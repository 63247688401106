import { BvMsgBoxOptions } from "bootstrap-vue";

export const confirmModal = async (
  that: Vue,
  confirmFn: Function,
  msg: string,
  title: string,
  options?: BvMsgBoxOptions
): Promise<void> => {
  const confirmed = await that.$bvModal.msgBoxConfirm(msg, {
    title,
    size: "sm",
    okVariant: "accent",
    okTitle: "Confirm",
    cancelTitle: "Close",
    titleTag: "h4",
    ...options
  });
  if (confirmed) {
    confirmFn();
  }
};
