














































































































































































































import LayoutWithPageHeader from "@/components/layout/LayoutWithPageHeader.vue";
import FormField from "@/components/form/FormField.vue";
import Form from "@/components/form/Form.vue";
import formMixin from "@/mixins/form";
import FieldGroup from "@/components/form/FieldGroup.vue";
import CountrySelect from "@/components/form/CountrySelect.vue";
import CurrencySelect from "@/components/form/CurrencySelect.vue";
import IconButton from "@/components/button/IconButton.vue";
import { required, email } from "vuelidate/lib/validators";
import mixins from "vue-typed-mixins";
import { Billing, BillingInput, ContactInfo } from "@/generated/graphql";
import { ActionTypes } from "@/store/action-types";
import { mapState } from "vuex";
import cloneDeep from "lodash.clonedeep";
import billingStore from "@/store/modules/billing";
import { BvModalEvent } from "bootstrap-vue";
import { confirmModal } from "@/modal";

const UPSERT_RECIPIENT_MODAL_ID = "upsert-recipient-modal";

export default mixins(formMixin).extend({
  components: {
    LayoutWithPageHeader,
    FormField,
    Form,
    FieldGroup,
    CountrySelect,
    CurrencySelect,
    IconButton
  },
  created() {
    this.$storeTyped.dispatch(ActionTypes.FETCH_BILLING);
  },
  computed: {
    ...mapState({
      currentOrg: state => state.organization.organization,
      currentBilling: state => state.billing.billing
    }),
    isCurrencyEditable() {
      return this.$storeTyped.getters.isCurrencyEditable;
    },
    isEditModal(): boolean {
      return typeof this.editedRecipient === "number";
    }
  },
  watch: {
    currentBilling: {
      handler: function(val: Billing) {
        this.form = cloneDeep(val);
      },
      immediate: true
    }
  },
  methods: {
    async onSubmit() {
      await this.$storeTyped.dispatch(
        ActionTypes.UPDATE_ORGANIZATION_BILLING,
        this.form
      );
    },
    openModal(recipient: ContactInfo, index: number) {
      this.$v.$reset();
      this.editedRecipient = index;
      this.upsertedRecipientForm = this.isEditModal
        ? {
            firstName: recipient.firstName,
            lastName: recipient.lastName,
            email: recipient.email
          }
        : {
            firstName: "",
            lastName: "",
            email: ""
          };
      this.$bvModal.show(this.upsertModalId);
    },
    upsertRecipient(e: BvModalEvent | null) {
      this.validate(this.$v.upsertedRecipientForm);
      if (!this.$v.upsertedRecipientForm.$anyError) {
        let recipients: ContactInfo[] | null;
        if (this.isEditModal) {
          recipients = this.form.recipients
            ? (this.form.recipients.map((r, i) => {
                return i === this.editedRecipient
                  ? { ...this.upsertedRecipientForm }
                  : { ...r };
              }) as ContactInfo[])
            : null;
        } else {
          recipients = this.form.recipients
            ? [
                ...(this.form.recipients as ContactInfo[]),
                { ...this.upsertedRecipientForm }
              ]
            : [{ ...this.upsertedRecipientForm }];
        }
        this.form = { ...this.form, recipients };
        this.$bvModal.hide(this.upsertModalId);
      } else if (e !== null) {
        e.preventDefault();
      }
    },
    deleteRecipient(email: string) {
      const confirmFn = () => {
        this.form = {
          ...this.form,
          recipients: this.form.recipients
            ? this.form.recipients.filter(r => r && r.email !== email)
            : null
        };
      };
      confirmModal(
        this,
        confirmFn,
        "Please confirm you want to remove this recipient.",
        "Recipient deletion"
      );
    }
  },
  data() {
    return {
      form: cloneDeep(billingStore.state.billing) as BillingInput,
      fields: ["firstName", "lastName", "email", "controls"],
      upsertedRecipientForm: {
        firstName: "",
        lastName: "",
        email: ""
      } as ContactInfo,
      upsertModalId: UPSERT_RECIPIENT_MODAL_ID,
      editedRecipient: null as number | null
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      addressLine1: {
        required
      },
      addressLine2: {},
      state: {},
      zip: {
        required
      },
      city: {
        required
      },
      country: {
        required
      },
      currency: {
        required
      },
      vat: {},
      recipients: {
        required
      }
    },
    upsertedRecipientForm: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      }
    }
  }
});
