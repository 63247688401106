













import mixins from "vue-typed-mixins";
import EnumSelect from "@/components/form/EnumSelect.vue";
import { countries } from "@/list";
import modelMixin from "@/mixins/model.ts";

export default mixins(modelMixin<{ name: string; code: string }>()).extend({
  name: "CountrySelect",
  components: { EnumSelect },
  props: {
    value: {
      type: Object,
      default: null
    },
    v: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default: "country"
    },
    label: {
      type: String,
      default: "Country"
    }
  },
  data() {
    return {
      countries
    };
  }
});
