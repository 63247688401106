















import mixins from "vue-typed-mixins";
import EnumSelect from "@/components/form/EnumSelect.vue";
import { currencies } from "@/list";
import modelMixin from "@/mixins/model";

const currency = currencies.USD;
export default mixins(modelMixin<typeof currency>()).extend({
  name: "CurrencySelect",
  components: { EnumSelect },
  props: {
    value: {
      type: String
    },
    v: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default: "currency"
    },
    label: {
      type: String,
      default: "Currency"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { currencies };
  },
  computed: {
    options(): typeof currency[] {
      return Object.entries(this.currencies).map(([, v]) => v);
    }
  }
});
